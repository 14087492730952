import React from "react";

export const closeIcon = (color:string = '#484848') => <svg color={color} height={20} width={20} aria-hidden="true" data-prefix="fas"
                                    data-icon="times"
                                    className="svg-inline--fa fa-times fa-w-11" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 352 512">
    <path fill="currentColor"
          d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
</svg>;

export const iconIllustrationTypes = {
    warning: <svg width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0C115.2 0 0 115.2 0 256s115.2 256 256 256 256-115.2 256-256S396.8 0 256 0zm0 51.2c28.16 0 48.64 23.04 46.08 51.2L281.6 307.2h-51.2l-20.48-204.8c-2.56-28.16 17.92-51.2 46.08-51.2zm0 409.6c-28.16 0-51.2-23.04-51.2-51.2 0-28.16 23.04-51.2 51.2-51.2s51.2 23.04 51.2 51.2c0 28.16-23.04 51.2-51.2 51.2z"/></svg>,
    info: <svg width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450.56 450.56"><path d="M225.28 0C101.376 0 0 101.376 0 225.28s101.376 225.28 225.28 225.28 225.28-101.376 225.28-225.28S349.184 0 225.28 0zm23.552 321.024h-48.128V183.296h48.128v137.728zm0-167.424h-48.128v-30.208h48.128V153.6z"/></svg>,
    success: <svg width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477.867 477.867"><path d="M238.933 0C106.974 0 0 106.974 0 238.933s106.974 238.933 238.933 238.933 238.933-106.974 238.933-238.933C477.726 107.033 370.834.141 238.933 0zm131.533 165.666L199.799 336.333c-6.665 6.663-17.468 6.663-24.132 0L107.4 268.066c-6.78-6.548-6.968-17.352-.42-24.132 6.548-6.78 17.352-6.968 24.132-.42.142.138.282.277.42.42l56.201 56.201 158.601-158.601c6.78-6.548 17.584-6.36 24.132.419 6.388 6.614 6.388 17.099 0 23.713z"/></svg>,
    danger: <svg width={20} xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 512 512"><path d="M501.362 383.95L320.497 51.474c-29.059-48.921-99.896-48.986-128.994 0L10.647 383.95c-29.706 49.989 6.259 113.291 64.482 113.291h361.736c58.174 0 94.203-63.251 64.497-113.291zM256 437.241c-16.538 0-30-13.462-30-30s13.462-30 30-30 30 13.462 30 30-13.462 30-30 30zm30-120c0 16.538-13.462 30-30 30s-30-13.462-30-30v-150c0-16.538 13.462-30 30-30s30 13.462 30 30v150z"/></svg>
}

